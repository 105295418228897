(<template>
  <page-data-section :title="sectionTitle"
                     :progress="isProgressActive"
                     class="comp-security-edit">
    <div v-if="!userCanEditInfo">
      <p class="comp-security-edit__sorry-message">{{ $gettext('Unfortunately you have no access to edit company information.') }}</p>
    </div>
    <div v-if="userCanEditInfo"
         class="comp-security-edit__content-wrapper">
      <security-form class="comp-security-edit__form"
                     @formstarted="isProgressActive = true"
                     @stopprogress="isProgressActive = false"
                     @formcompleted="completeForm" />
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import EnterpriseSecurityForm from '@/components/enterprise/EnterpriseSecurityForm';

  export default {
    asyncData({store}) {
      const myEntId = store.getters['UserInfoStore/enterpriseId'];
      const canEdit = store.getters['UserInfoStore/userIsAdminOfEnterprise'];
      let promiseArr = '';
      if (canEdit && !store.state.TTEnterpriseSecurityStore.securityInfo) {
        promiseArr = [...promiseArr, store.dispatch('TTEnterpriseSecurityStore/getEnterpriseSecurity', myEntId)];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'security-form': EnterpriseSecurityForm
    },
    data() {
      return {
        isProgressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise']),
      ...mapState('TTEnterpriseInfoStore', {
        enterpriseOwnerUid: (state) => state.companyInfo.owner_uid || ''
      }),
      userCanEditInfo() { return this.userIsAdminOfEnterprise || this.userIsOwnerOfEnterprise || this.$isGodmode(); },
      userIsOwnerOfEnterprise() { return this.userUid == this.enterpriseOwnerUid; },
      sectionTitle() { return this.$gettext('SAML Configuration'); }
    },
    methods: {
      ...mapMutations('TTEnterpriseSecurityStore', [
        'setError',
        'removeOneError',
      ]),
      completeForm() {
        this.$router.push(this.$makeRoute({name: 'BuyerSecurity'}));
      }
    },
    beforeMount() {}
  };
</script>

<style>
  .comp-security-edit .comp-security-edit__form .security-form__btns-wrapper {
    justify-content: flex-start;
    margin-top: 20px;
  }

  .comp-security-edit .comp-security-edit__form .security-form__btn--cancel {
    display: none;
  }
</style>

<style scoped>
  .comp-security-edit__content-wrapper {
    display: flex;
  }

  .comp-security-edit__form.security-form {
    max-width: 380px;
    margin: 0;
    padding-right: 20px;
  }

  @media (max-width: 600px) {
    .comp-security-edit__content-wrapper {
      flex-direction: column-reverse;
    }

    .comp-security-edit__form.security-form {
      width: 100%;
      max-width: initial;
      padding: 0;
    }

    .comp-security-edit__avatar-label-wrapper {
      margin: 0;
      margin-bottom: 40px;
    }
  }
</style>
